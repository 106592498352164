@import "src/styles/breakpoints";
@import "src/styles/variables";

.visit-card {
  background-color: $cardColor;
  border: 1px solid $cardColor;
  border-radius: 0.5rem;
  display: block;
  flex: 0 0 auto;
  margin-bottom: 1rem;
  transition: all 300ms;
  width: 100%;

  &.highlighted {
    background-color: $cardColor;
    border-color: $gray;
  }

  &.darkened {
    background-color:rgba(24, 43, 73, 0.5);
  }

  a {
    background-color: transparent;
    flex: 1
  }

  @include breakpoint("tablet") {
    display: flex;
    align-items: center;
  }
}