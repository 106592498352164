@import "src/styles/variables";

.customer-removed {
  background: $navy-blue;
  width: 100%;
  padding: 4rem;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}