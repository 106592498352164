@import "src/styles/breakpoints";
@import "styles/variables";

.lobby-switcher {
  position: relative;
  cursor: pointer;

  .current-lobby {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    padding: 0.25rem 0;
  }

  .lobby-menu {
    background: #182B49;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.64);
    border-radius: 0.25rem;
    display: none;
    overflow: hidden;
    position: absolute;
    top: calc(100% + 0.25rem);
    left: -0.75rem;
    right: 0;
    padding: 0.25rem;
    z-index: 10;

    li {
      margin-bottom: 0;
      padding: 0.5rem 0.75rem;
      white-space: nowrap;

      &:hover {
        background-color: $dark;
      }
    }
  }

  .icon {
    margin-left: 0.5rem;
    transition: 150ms ease-in;
  }

  &.open {

    .lobby-menu {
      display: block;
    }

    .icon {
      transform: rotate(180deg);
    }
  }
}