@import "styles/mixins";
@import "styles/variables";

.join-form {

  margin-bottom: 2rem;

  h3 {
    margin-bottom: 2rem;
    text-align: center;
  }

  button {
    @include buttonPrimary;
  }
} 