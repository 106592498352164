@import "src/styles/variables";

.visits-count {
  position: relative;
  width: 4rem; 
  height: 4rem;
  border-radius: 50%;
  transition: 1s;
  color: #00bff3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;

  &:after {
    position: absolute;
    top: 50%; left: 50%;
    margin: -1.7rem;
    width: 3.4rem; height: 3.4rem;
    border-radius: 50%;
    background: $cardColor;
    content: ''
  }

  span {
    z-index: 10;

    &.empty {
      color: #757575;
    }
  }
}