@import "src/styles/breakpoints";

.mobile-only {
  display: block;
  @include breakpoint("tablet") {
    display: none;
  }
}

.tablet-only {
  display: none;
  @include breakpoint("tablet") {
    display: block;
  }
}

.text-center {
  text-align: center;
}