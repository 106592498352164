@import "src/styles/mixins";
@import "src/styles/variables";

.checkbox {
  align-items: baseline;
}

.label {
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  line-height: 1.5;
  white-space: pre-wrap;
}