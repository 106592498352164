.trial-status {
  display: flex;

  &-icon {
    flex: 0 0 30px;
    display: flex;
    align-items: center;
    height: 24px;
  }

  svg {
    fill: #ffb35c
  }

  strong {
    color: #ffb35c;
    font-size: 16px;
  }

  p {
    color: #c1cddb;
    font-size: 12px;
  }
}