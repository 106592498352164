@import "src/styles/variables";

.new-button {
  background-color: $dark-green;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;

  &.disabled {
    background-color: darken($dark-green, 5%);
    cursor: not-allowed;
    opacity: 0.75;

    &:hover {
      background-color: darken($dark-green, 5%);
    }
  }

  &:hover {
    background-color: darken($dark-green, 3%);
  }

  span {
    font-weight: 700;
  }

  .plus-button {
    border: 3px solid $green;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    height: 64px;
    width: 64px;

    svg {
      fill: $green;
    }
  }
}