@import "src/styles/breakpoints";
@import "src/styles/variables";

.visit-card-actions {
  display: flex;

  @include breakpoint("tablet") {
    padding: 1rem 1rem 1rem 2rem;
  }

  form {
    flex: 1;

    button {
      background-color: #0a4365;
      border-radius: 0;
      box-shadow: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      width: 100%;
      svg { margin-right: 0.5rem }

      &:disabled {
        cursor: default;
        opacity: 0.65;
      }

      @include breakpoint("tablet") {
        background-color: $dark;
        border-radius: 8px;
        height: 56px;
        padding: 1rem;

        &:hover {
          background-color: darken($dark, 3%);
        }

        &:disabled:hover {
          background-color: $dark;
        }
      }
    }

    &.remove {
      button {
        border-right: 1px solid $cardColor;
        border-bottom-left-radius: 0.5rem;
        color: $red;
      }

      @include breakpoint("tablet") {
        border: none;
        span { display: none }
        svg { margin-right: 0 }
        margin-right: 1rem
      }
    }

    &.send-invite {
      button {
        border-left: 1px solid $cardColor;
        border-bottom-right-radius: 0.5rem;
        color: $light-blue;

        @include breakpoint("tablet") {
          border: none;
        }

        span.sent {
          color: $green
        }
        span.failed {
          color: $red;
        }
      }
    }
  }
}