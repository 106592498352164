@import "src/styles/breakpoints";
@import "src/styles/mixins";
@import "src/styles/variables";

.textArea {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1.5rem;

  textarea {
    @include formControl;
    height: auto;
    resize: none;
    width: 100%;
  }
}

.helperText {
  color: $gray;
  font-size: 0.85rem;
  margin-top: 0.5rem
}

.hasErrors {
  textarea {
    background-color: #521517;
    border: 1px solid $bright-red;
  }
}