@import "src/styles/breakpoints";

.settings-container {
  display: flex;
  height: 100vh;
  overflow-y: hidden;

  .settings-page {
    flex: 1;
    padding: 0 0.75rem;
    width: 100%;

    @include breakpoint("tablet") {
      padding: 0 1rem;
    }
  }
}