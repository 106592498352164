@import "src/styles/breakpoints";
@import "src/styles/variables";

.lobby {
  height: 100%;
  position: relative;

  &-visits {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 12rem;
    z-index: 10;

    @include breakpoint("tablet") {
      padding-bottom: 0;
     }
  }

  .visit-enter {
    opacity: 0;
  }
  .visit-enter-active {
    opacity: 1;
    transition: opacity 250ms ease-in;
  }
  .visit-exit {
    opacity: 1;
  }
  .visit-exit-active {
    opacity: 0;
    transition: opacity 250ms ease-in;
  }

  .mobile-invite {
    background: linear-gradient(180deg, rgba(10, 2, 19, 0) 0%, rgba(10, 2, 19, 0.88) 100%);
    padding: 4rem 0 1rem 0;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    
    form,
    .dummy-button {
      margin: 0 0.5rem;
      pointer-events: all;
    }

    @include breakpoint("tablet") {
      display: none;
     }
  }
}