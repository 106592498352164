@import "src/styles/breakpoints";
@import "styles/variables";

.empty-lobby {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;

  @include breakpoint("tablet") {
    display: block;
    padding: 1.5rem 0;
  }
  
  img {
    max-height: 313px;
    width: 100%;

    @include breakpoint("tablet") {
      max-height: initial;
      max-width: 702px;
    }
  }

  .instructions {
    margin: 0 auto;
    max-width: 390px;

    h3 {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 1rem;
      text-align: center;

      @include breakpoint("tablet") {
        text-align: left;
      }
    }

    p {
      color: $gray;
      margin-bottom: 1rem;
    }

    @include breakpoint("tablet") {
      padding-top: 2rem;
      position: relative;
      left: 140px
    }
  }

  &-image {
    flex: 1;
    position: relative;
    text-align: center;
    width: 100%;

    @include breakpoint("tablet") {
      bottom: 180px;
    }
  }
}