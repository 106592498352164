.awaiting-logo {
  height: 55px;
  min-height: 55px;
  width: 120px;

  &.mobile-header {
    height: 32px;
    min-height: 32px;
    width: 87px;
  }
}