@import "src/styles/breakpoints";
@import "src/styles/mixins";
@import "src/styles/variables";


.manage-waitlists {
  display: flex;
  flex-direction: column;
  height: 100%;

  .lobby-list {
    margin-bottom: 2rem;

    .lobby {
      background-color: $cardColor;
      border-radius: 0.5rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      padding: 1rem 1.5rem;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }

      &-left {
        display: flex;
        align-items: center;
      }

      &-info {
        margin-left: 1rem;
      }

      &-name {
        font-weight: 700;
      }

      &-phone {
        color: $gray;
        font-size: 14px;
      }

      &-actions {
        padding-left: 2rem;

        span {
          color: $light-blue;
        }
      }
    }
  }

  .trial-message {
    margin-bottom: 1.5rem;
    a {
      @include buttonLink();
      color: #088bd9;
      font-weight: normal;
      padding-left: 0;
      padding-right: 0;
      text-decoration: underline;
    }
  }

  button {
    color: #088bd9;
    font-weight: normal;
    padding-left: 0;
    padding-right: 0;
    text-decoration: underline;
  }
}