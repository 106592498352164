@import "src/styles/breakpoints";
@import "src/styles/mixins";
@import "src/styles/variables";

@mixin section {
  margin: 0 1rem 1rem 1rem;
}

@mixin portlet {
  background-color: $cardColor;
  border-radius: 0.5rem;
  padding: 1.5rem;
}

$sidebar-width: 320px;

.reports-container {
  display: flex;
  overflow-y: hidden;
}

.report {
  flex: 1;
  // height: 100vh;
  overflow-y: auto;
  
  @include breakpoint("tablet") {
    margin-left: $sidebar-width;
    .offset-top {
      margin-top: 5rem;
    }
  }

  h4 {
    font-weight: bold;
  }

  &-header {
    background: linear-gradient(143.13deg, #010d1d 0%, $dark-blue 100%);
    padding: 1rem;
    margin-top: 4rem;

    @include breakpoint("tablet") {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      display: flex;
      justify-content: space-between;
      align-items: center;
      left: $sidebar-width;
      margin-top: 0;
    }

    .location-name,
    .current-lobby {
      font-size: 1.25rem;
      font-weight: 600;
      margin-bottom: 0.5rem;

      @include breakpoint("tablet") {
        margin-bottom: 0;
      }
    }
  }

  &-portlet {
    @include portlet;
  }

  &-summary {
    .stat-row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
  }

  &-waits {
    h4 {
      margin-bottom: 1rem;
    }
    .stat-row {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      grid-row-gap: 1rem;
    }
  }

  &-summary,
  &-waits { 
    @include section;

    .with-loading {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .stat {
      flex: 1;
      text-align: center;

      &-row {
        @include breakpoint("tablet") {
          display: flex;
          align-items: center;
          justify-content: space-evenly;
        }
      }

      span {
        display: inline-block;
        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;

        &.stat-large {
          font-size: 2.5rem;
        }
      }
      h5 {
        color: $gray;
        font-size: 1rem;
        font-weight: normal;
      }
    }
  }

  &-chart {
    @include section;
    h4 {
      margin-bottom: 1rem;
    }
  }

  &-table {
    padding: 0 1rem 1rem 1rem;
    h4 {
      font-size: 1rem;
      margin-bottom: 1rem;
    }
  }
}

