.new-waitlist {
  display: flex;
  flex-direction: column;
  height: 100%;

  p {
    button {
      color: #088bd9;
      font-weight: normal;
      padding-left: 0;
      padding-right: 0;
      text-decoration: underline;
    }
  }
}