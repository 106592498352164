@import "src/styles/breakpoints";
@import "src/styles/mixins";
@import "src/styles/variables";

.textInput {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 1.5rem;

  label {
    color: #fff;
    margin-bottom: 0.5rem;

    @include breakpoint("tablet") {
      font-size: 16px;
    }
  }

  &.disabled {
    input {
      background-color: $dark;
    }
  }

  input {
    @include formControl;
  }
}

.maskWrap {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
}

.phoneMask {
  color: rgba(250, 252, 255, 0.5);
  font-size: 20px;
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  pointer-events: none;
  display: flex;
  align-items: center;

  span:first-child {
    margin-right: 46px;
  }
  span:last-child {
    margin-right: 8px;
  }
}

.hasErrors {
  input {
    background-color: #521517;
    border: 1px solid $bright-red;
  }
}
