.lobby-form {
  margin-bottom: 2rem;
  .area-code {
    input {
      padding-left: 1.75rem;
    }
  }

  .billing-warning {
    margin-bottom: 2rem;
  }
}