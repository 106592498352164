@import "src/styles/mixins";
@import "src/styles/variables";

.role-manager {

  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .role-selector {
    display: flex;
    margin-bottom: 1.5rem;

    .role {
      background-color: #0a4365;
      border: 2px solid #0a4365;
      border-radius: 0.5rem;  
      cursor: pointer;
      flex: 1;
      padding: 0.75rem;

      &.selected {
        background-color: lighten(#0a4365, 3%);
        border-color: $green;
      }

      p {
        color: $white;
        font-size: 12px
      }

      &:first-child {
        margin-right: 0.25rem;
      }

      &:last-child {
        margin-left: 0.25rem
      }
    }
  }

  &.disabled {
    .role-selector {
      .role {
        background-color: #434f5f;
        border-color: #434f5f;
        cursor: default;

        &.selected {
          border-color: $green;
        }
      }
    }
  }

  .lobby-list {

    &.hide {
      display: none;
    }

    p {
      margin-bottom: 1rem;
    }

    label {
      color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      position: relative;
      line-height: 1.5;
      white-space: pre-wrap;
    }
  }
}