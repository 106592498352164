$breakpoints: (
  "tablet": 992px,
  "desktop": 1200px,
  "wide": 1400px
);

/*
  Usage:

  .content {
    color: black;

    @include breakpoint("tablet") {
      color: white;
    }
  }
*/
@mixin breakpoint($bp) {
  @if map-has-key($breakpoints, $bp) {
    @media (min-width: map-get($breakpoints, $bp)) {
      @content;
    }
  } @else {
    @warn "Breakpoint `#{$bp}` is invalid.\n"
        + "Available breakpoints: #{map-keys($breakpoints)}.";
  }
}
