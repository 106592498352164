@import "src/styles/mixins";

.mobile-nav {
  background-color: inherit;
  max-height: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  padding: 0 0.5rem;
  position: relative;
  transition: all 300ms;

  .nav-top {
    border-bottom: 1px solid rgba(255, 255, 255, 0.24);
    padding-bottom: 1.25rem;
  }

  .location-name {
    font-weight: 600;
  }

  .location-number {
    color: $gray;
    margin-bottom: 1.5rem;
  }

  .nav-bottom {
    padding-top: 1.25rem;
  }

  ul {
    li {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }

      a { color: inherit }

      button {
        background-color: transparent;
        color: inherit;
      }
    }
  }
}

.nav-open {
  nav {
    max-height: 100vh;
    height: 100vh;
    opacity: 1;
  }
}