@import "src/styles/breakpoints";
@import "src/styles/variables";

.settings-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  padding: 2rem 0.25rem;

  @include breakpoint("tablet") {
    max-width: 500px;
    width: 100%;
  }

  .mobile-toggle {
    display: flex;
    
    svg {
      fill: $gray;
    }

    @include breakpoint("tablet") {
      display: none;
    }
  }
}