@import "styles/breakpoints";
@import "styles/variables";

.react-toast-notifications__container {
  width: 100%;

  @include breakpoint("tablet") {
    width: 360px;
  }
}

.custom-toast-wrapper {
  position: relative;
}

.custom-toast {
  border-radius: 4px;
  color: $dark;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  transform: translate3d(0, 0, 0);
  transition: transform 220ms cubic-bezier(0.2, 0, 0, 1), opacity 220ms;

  .toast-icon {
    margin-right: 0.75rem
  }

  .toast-close {
    cursor: pointer;
    fill: rgba(0, 0, 0, 0.24);
    z-index: 100;
  }

  &.success {
    background-color: $green;
  }
}

@keyframes countdownBar {
  from { width: 0% }
  to { width: 100% }
}

.custom-toast-overlay {
  animation: countdownBar 4000ms linear;
  background-color: rgba(0,0,0,0.24);
  opacity: 0.6;
  height: 100%;
  width: 0%;
  position: absolute;
  bottom: 0;
  right: 0;
  pointer-events: none;
}