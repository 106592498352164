@import "src/styles/breakpoints";
@import "src/styles/variables";
@import "src/styles/mixins";

.button {
  @include button;

  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &[type="submit"] {

    &:focus {
    }

    &:active {
    }
  }

  &.send_invite {
    @include breakpoint("tablet") {
      width: 145px;
    }
  }
}
