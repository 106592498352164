@import "src/styles/breakpoints";
@import "src/styles/variables";

.report-table {
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  table {
    font-size: 14px;
    width: 100%;

    tbody {
      overflow-y: auto;
    
      tr {
        background-color: $dark;
        border-bottom: 2px solid $dark-blue;
      }
    }
    
    th {
      // background: #011026;
      color: $gray;
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
      position: sticky;
      top: 0;
    }
    
    th,
    td {
      padding: 0.5rem;
      overflow: hidden;
      white-space: nowrap;

      @include breakpoint("tablet") {
        white-space: initial;
      }

      &.text-center {
        text-align: center;

        .status-tag {
          margin: 0 auto
        }
      }
    }
  }

  .no-visitors {
    padding: 0.5rem 0;
    em {
      font-style: italic;
    }
  }
}