@import "src/styles/breakpoints";
@import "src/styles/variables";

.message {
  align-self: flex-start;
  display: inline-block;
  margin-bottom: 1rem;
  max-width: 80%;

  @include breakpoint("tablet") {
    max-width: 60%;
  }

  &-body {
    background-color: #213c70;
    border-radius: 1rem;
    padding: 0.75rem;
    margin-bottom: 0.25rem;

    @include breakpoint("tablet") {
      font-size: 1.25rem;
      padding: 1rem;
    }
  }

  p {
    word-break: break-word;
  }

  &-timing {
    color: $gray;
  } 

  &-delivered {
    color: $green;
    margin-left: 0.5rem;
  }

  &-error {
    color: $red;
    margin-left: 0.5rem;
  }

  &.out {
    align-self: flex-end;

    .message-body {
      background-color: #13203e;
    }

    .message-meta {
      text-align: right;
    }
  }
}