$white: #fafcff;
$gray: #c1cddb;
$green: #1ce1ac;
$dark-green: #0c5541;
$alt-green: #19b58b;
$blue: #088bd9;
$light-blue: #00bff3;
$bright-red: #ff8585;
$red: #b42f32;
$dark-red: #521517;
$orange: #ffb35c;
$dark: #12213e;
$dark-blue: #001737;
$navy-blue: #213C70;
$bodyColor: linear-gradient(143.13deg, #010d1d 0%, $dark-blue 100%);
$cardColor: #182b49;
