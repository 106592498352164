@import "src/styles/breakpoints";
@import "src/styles/variables";

.edit-waitlist {
  display: flex;
  flex-direction: column;
  height: 100%;

  .download-signs {
    margin: 4rem 0;

    h3 {
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 1rem;
    }

    .buttons {
      margin-top: 4rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 12px;

      @include breakpoint("tablet") {
        grid-column-gap: 24px;
      }
    }

    .download-button {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      border: 1px dashed $light-blue;
      border-radius: 0.5rem;
      padding: 1.5rem 1.5rem 0 1.5rem;
      text-align: center;

      @include breakpoint("tablet") {
        padding: 2rem 3rem 0 3rem;
      }

      &:hover {
        background-color: darken(#001737, 4%);
        border-style: solid;
      }

      &.loading {
        background-color: darken(#001737, 4%);
        cursor: default;
        opacity: 0.5;
      }

      .download-image {
        flex: 1;
        margin-bottom: 2rem;
        display: flex;
        align-items: center;
      }

      img {
        width: 100%;
      }

      .download-text {
        font-size: 12px;
        font-weight: 700;
        color: $light-blue;

        @include breakpoint("tablet") {
          font-size: 14px;
        }
      }

      .loading {
        display: inline-block;
        height: 24px;
        margin-bottom: 1rem;
      }
    }
  }
}