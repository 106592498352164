@import "src/styles/variables";

@mixin button() {
  display: flex;	
  align-items: center;	
  justify-content: center;
  font-weight: bold;
  min-height: 48px;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s, box-shadow 0.2s;
}

@mixin buttonLink() {
  display: flex;	
  align-items: center;
  min-height: 48px;
  padding: 15px;
}

@mixin buttonPrimary() {
  background-color: $blue;
  border-radius: 100px;
  color: #fff;
  line-height: 1.5;
  width: 100%;

  &:hover {
    background-color: darken($blue, 5%);
  }

  &:disabled {
    background-color: #088db9;
    opacity: 0.5;
  }
}

@mixin formControl {
  background-color:  #0a4365;
  border: none;
  border-radius: 8px;
  box-shadow: none;
  color: $white;
  display: block;
  padding: 1rem;
  font-family: inherit;
  font-size: 20px;
  line-height: 1.5;

  &:disabled {
    opacity: 0.9;
  }
}