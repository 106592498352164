@import "src/styles/breakpoints";
@import "src/styles/mixins";
@import "src/styles/variables";

.users-list {

  .user {
    background-color: $cardColor;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    &-name {
      font-weight: bold;
    }

    &-status {
      color: $gray;
      font-size: 14px;
      line-height: 24px;

      .pending {
        color: $orange;
      }
    }

    &-lobby {
      color: $gray;
      font-size: 12px;
      line-height: 24px;
    }

    &-edit {
      color: $light-blue;
      padding: 0 1rem;
    }

    &-actions {
      span {
        @include buttonLink();
        color: #00bff3;
      }
    }
  }
}