@import "src/styles/breakpoints";
@import "src/styles/variables";
@import "src/styles/mixins";

.visitor-detail {
  display: flex;
  flex: 1 0 auto;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 150ms ease-out;
  height: 100%;
  width: 100%;
  z-index: 100;

  @include breakpoint("tablet") {
    width: 640px;
    flex-basis: 640px;
    padding: 1.5rem 1.5rem 0 0;
  }

  @include breakpoint("wide") {
    width: 710px;
    flex-basis: 710px;
  }

  &.active {
    transform: translateX(0);
  }

  .visitor-content {
    background-color: #182b49;
    box-shadow: -4px 0px 4px 1px #010d1d;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    width: 100%;
  }

  .visitor-footer {
    box-shadow: 0 -4px 0 #12213e;
    text-align: right;
    z-index: 100;

    .visit-card-actions {

      @include breakpoint("tablet") {
        display: inline-flex;

        form.remove {
          svg { margin-right: 0.5rem }
          span { display: block }
        }
      }
    }
  }

  .visitor-visits {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .visitor-visit {
    border-bottom: 1px dashed rgba(200, 200, 210, 0.25);
    padding: 1rem;

    &:first-child {
      margin-top: auto;
    }

    &:last-of-type {
      border-bottom: 0
    }

    .visit-header {
      
      display: flex;
      justify-content: space-between;
      margin: 1rem 0;

      div:last-child {
        text-align: right;
      }

      h5 {
        font-size: 14px;
      }

      h5.muted {
        color: $gray;
        font-weight: normal;
      }

      h5.waited {
        color: #1ce1ac;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-weight: normal;
        svg {
          fill: #1ce1ac;
          margin-right: 5px;
        }
      }
    }
  }

  .visitor-messages {
    display: flex;
    flex-direction: column;
  }
}