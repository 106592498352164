@import "src/styles/breakpoints";

.settings-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 2rem;

  .settings-scroll {
    padding: 0 0.25rem;
    
    @include breakpoint("tablet") {
      width: 500px;
    }
  }
}