@import "src/styles/breakpoints";
@import "src/styles/variables";

main {
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  @include breakpoint("tablet") {
    flex-direction: row;
    padding: 1.5rem 1.5rem 0 1.5rem
  }

  @include breakpoint("desktop") {
    height: 100vh;
  }

  .content {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 68%;
    display: flex;
    flex-direction: column;
    height: 100%;

    @include breakpoint("tablet") {
      min-width: 680px
    }

    &.shrunk {
      flex-basis: 50%;
      transition-delay: 150ms;
    }
  }
}