@import "styles/mixins";
@import "styles/variables";

.account-manage {
  padding-bottom: 40px;

  a, button {
    color: $blue;
    font-weight: normal;
    padding-left: 0;
    padding-right: 0;
    text-decoration: underline;
  }
}