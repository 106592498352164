.visit {
  
  footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 2rem 0;
    text-align: center;
  }
  .awaiting-logo {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
