@import "src/styles/mixins";

.menu-button {

  button,
  a {
    @include buttonLink();
    background: transparent;
    background-repeat: no-repeat;
    background-position: center left;
    box-shadow: none;
    color: inherit;
    font-weight: normal;
    min-height: auto;
    padding: 0 0 0 2rem;

    svg {
      margin-right: 0.5rem;
    }

    span {
      margin-right: 0.5rem
    }

    &:disabled {
      color: #999;
    }

    &.sign {
      background-image: url("../assets/sign.svg");
    }

    &.account {
      background-image: url("../assets/credit-card.svg");
    }

    &.reports {
      background-image: url("../assets/line-chart.svg");
    }

    &.qr-code {
      background-image: url("../assets/qr-code.svg");
    }

    &.return {
      background-image: url("../assets/redo.svg");
    }

    &.sign-out {
      background-image: url("../assets/peace-out.svg");
    }

    &.users {
      background-image: url("../assets/users.svg");
    }

    &.settings {
      background-image: url("../assets/gear.svg");
    }
    
    &.support {
      background-image: url("../assets/help.svg");
    }

    &.waitlist {
      background-image: url("../assets/place.svg");
    }
  }
}