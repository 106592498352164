@import "styles/mixins";
@import "styles/variables";

.lobby-form {

  margin-bottom: 2rem;

  h3 {
    margin-bottom: 2rem;
    text-align: center;
  }

  .label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    button {
      @include buttonLink;
      color: #00bff3;
      font-size: 12px;
      min-height: initial;
      padding: 0;
    }
  }

  .link-message {
    display: flex;
    flex-flow: column nowrap;
    
    &.hide {
      display: none
    }
  }

  button.submit {
    @include buttonPrimary;
  }
} 