@import "src/styles/variables";

.place-in-line {
  background: $blue;
  width: 100%;
  padding: 4rem;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  &.admitted {
    background: $alt-green;
    > .checkmark {
      font-size: 3rem;
      color: $white;
    }
  }
}
.number {
  font-size: 4rem;
  font-weight: 600;
}
