@import "src/styles/variables";
@import "src/styles/mixins";

.invite-button {
  @include buttonPrimary;
  height: 75px;

  strong {
    font-weight: 600;
    display: block;
  }
  span {
    font-size: 12px;
  }

  &:disabled {
    background-color: #213c70;
    cursor: default;
    opacity: 1;
  }
}