fieldset {
  margin-bottom: 0.5rem;
}

.field {
  margin-bottom: 1.5rem;
}

// new component if need another one
input[type=checkbox],
input[type=radio] {
  cursor: pointer;
  margin-right: 0.5rem;
  opacity: 1;
  vertical-align: baseline;
}

.checkbox {
  color: $gray;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  line-height: 1.5;
  white-space: pre-wrap;
}

select {
  @include formControl;
  height: 62px; // safari
  width: 100%;
}