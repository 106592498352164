@import "src/styles/breakpoints";
@import "src/styles/mixins";
@import "src/styles/variables";

.reports-pitch {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 2rem 0;

  @include breakpoint("tablet") {
    padding: 0;
    margin-left: 320px;
    .offset-top {
      margin-top: 5rem;
    }
  }

  .pitch-header {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    grid-template-rows: 1fr;    

    .mobile-toggle {
      display: flex;
      
      svg {
        fill: $gray;
        margin-left: auto;
      }
  
      @include breakpoint("tablet") {
        display: none;
      }
    }
  }

  button {
    @include buttonPrimary;
    margin-top: 2rem;
  }

  .instructions {
    margin: 0 auto 3rem auto;
    max-width: 390px;

    h3 {
      color: $green;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 1rem;
      text-align: center;

      @include breakpoint("tablet") {
        text-align: left;
      }
    }

    p {
      color: $gray;
      margin-bottom: 1rem;
    }

    @include breakpoint("tablet") {
      padding-top: 2rem;
    }

    a {
      @include button;
      @include buttonPrimary;
    }
  }

  &-image {
    flex: 1;
    position: relative;
    text-align: center;
    width: 100%;

    img {
      max-height: 313px;
      width: 100%;
  
      @include breakpoint("tablet") {
        max-height: initial;
        max-width: 520px;
      }
    }
  }
}