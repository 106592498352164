@import "src/styles/breakpoints";
@import "src/styles/variables";

.settings-sidebar {
  background-color: $dark-blue;
  box-shadow: -4px 0px 4px 1px #010d1d;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: space-between;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  transition: transform 150ms ease-out;
  height: 100%;
  width: 90%;
  z-index: 100;
  padding: 1.5rem;

  @include breakpoint("tablet") {
    background-color: transparent;
    flex-basis: 320px;
    position: initial;
    transform: none;
  }

  &.open {
    transform: translateX(0);
  }

  .menu {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .mobile-close {
      display: flex;
      @include breakpoint("tablet") {
        display: none;
      }
    }
  }

  ul {
    li {
      font-size: 20px;
      padding: 0.5rem 0.75rem;

      &.active {
        background-color: rgba(24, 43, 73, 0.6);
        border-radius: 0.5rem;
        color: #fff;
      }
    }
  }
}