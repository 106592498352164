@import "src/styles/variables";

.visit {
  min-height: calc(100vh - 100px); /* to account for safari ios bottom menu */
  padding: 3rem 1rem;
  position: relative;

  h4 {
    font-size: 1.25rem;
    margin-bottom: 2rem;
  }
  .content,
  .wrapper {
    text-align: center;
    padding: 0 1rem;
    margin: 0 auto 2rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 420px;
    width: 100%;
  }

  .content {
    padding-bottom: 4rem;
  }

  &-action {
    margin: 3rem auto;
  }

  button.cancel {
    color: $blue
  }
  .description {
    margin-bottom: 2rem;
    color: $gray;
    
  }
  .timestamp {
    font-family: monospace;
    font-size: 14px;
  }
}
