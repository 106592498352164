@import "src/styles/breakpoints";
@import "src/styles/mixins";
@import "src/styles/variables";

.auth-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 3rem 0.5rem;

  &.auth-top {
    justify-content: flex-start;
  }

  @include breakpoint("tablet") {
    justify-content: center;
    margin-top: 0
  }

  .auth-logo {
    margin-bottom: 2.5rem;
  }

  .auth-form {
    margin-bottom: 2rem;
    max-width: 400px;
    width: 100%;

    @include breakpoint("tablet") {
      margin: 0 auto 2rem auto;
    }

    button[type="submit"] {
      @include buttonPrimary;
      width: 100%;
    }

    .terms {
      margin: 2rem 0;
    }
  }

  h3 {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 2rem;
  }

  p {
    color: $gray;
    line-height: 1.5;
    margin-bottom: 2rem;
    padding: 0 0.5rem;
    max-width: 400px;
  }

  a {
    color: #088bd9;
    text-decoration: underline;
  }
  
  .resend-confirmation {
    text-decoration: underline
  }

  .sign-in-link {
    @include buttonLink;
  }

  .error-message {
    text-align: center;

    svg {
      margin-bottom: 0.5rem;
    }

    p {
      color: $bright-red;
      line-height: 1.5;
    }
  }

  .success-message {
    text-align: center;

    svg {
      margin-bottom: 2.25rem;
    }

    p {
      color: $green;
      font-size: 24px;
      line-height: 1.5;
    }

    .button-link {
      @include button;
      @include buttonPrimary;
      text-decoration: none;
    }
  }

  .email-confirmed {
    background: $green;
    border-radius: 0.25rem;
    margin-bottom: 2rem;
    padding: 1rem;

    p {
      color: $dark;
      margin-bottom: 0;
    }
  }
}