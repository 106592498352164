@import url("https://fonts.googleapis.com/css?family=Inter:400,500,600,700&display=swap");
@import "styles/reset";
@import "styles/variables";
@import "styles/buttons";
@import "styles/forms";
@import "styles/typography";
@import "styles/utils";

body {
  background: $bodyColor;
  color: $white;
  margin: 0;
  font-family: 'Inter', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  background-attachment: fixed;

  &.no-scroll {
    overflow: hidden;
  }
}

.container {
  height: 100%;
}
