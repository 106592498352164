@import "src/styles/breakpoints";
@import "src/styles/mixins";
@import "styles/variables";

.expired-lobby {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  @include breakpoint("tablet") {
    display: block;
    padding: 1.5rem 0;
  }

  button {
    @include buttonPrimary;
  }

  .instructions {
    margin: 0 auto 3rem auto;
    max-width: 390px;

    h3 {
      color: #ff8585;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 1rem;
      text-align: center;

      @include breakpoint("tablet") {
        text-align: left;
      }
    }

    p {
      color: $gray;
      margin-bottom: 1rem;
    }

    @include breakpoint("tablet") {
      margin-left: 300px;
      padding-top: 2rem;
    }

    a {
      @include button;
      @include buttonPrimary;
    }
  }

  &-image {
    flex: 1;
    position: relative;
    text-align: center;
    width: 100%;

    @include breakpoint("tablet") {
      left: -25px;
    }

    img {
      max-height: 313px;
      width: 100%;
  
      @include breakpoint("tablet") {
        max-height: initial;
        max-width: 520px;
      }
    }
  }
}