@import "src/styles/breakpoints";
@import "src/styles/mixins";
@import "src/styles/variables";

.range-picker {
  @include breakpoint("tablet") {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  select {
    height: 40px;
    font-size: 1rem;
    padding: 0.5rem;
    margin-bottom: 1rem;

    @include breakpoint("tablet") {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }

  .dates {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0.25rem;
    
    @include breakpoint("tablet") {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  

  .start-date,
  .end-date {
    display: flex;
    align-items: center;

    span {
      display: none;
      font-weight: 600;
      margin-right: 0.5rem;

      @include breakpoint("tablet") {
        display: inline;
      }
    }
  }

  .start-date {
    @include breakpoint("tablet") {
      margin-right: 1rem;
    }
  }
}

// Datepicker overrides
.react-datepicker__navigation {
  top: 10px;
}
.react-datepicker-wrapper {
  input {
    background: url(calendar.svg);
    background-repeat: no-repeat;
    background-position: 95% center;
    @include formControl;
    cursor: pointer;
    font-size: 1rem;
    padding: 0.5rem;
    width: 100%;

    @include breakpoint("tablet") {
      width: auto
    }
  }
}