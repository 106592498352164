@import "src/styles/variables";

.status-tag {
  border-radius: 0.25rem;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0.25rem 0;
  text-align: center;
  width: 4.5rem;

  &.removed {
    background-color: $red;
  }

  &.canceled {
    background-color: darken($orange, 30%);
  }

  &.visited {
    background-color: $dark-green;
  }

  &.waiting {
    background-color: $blue;
  }
}